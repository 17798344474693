import React from "react"
import { Helmet } from "react-helmet"
import "../styles/style.css"
import { graphql, Link, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../components/header"
import Footer from "../components/footer"

const Policy = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "cabecera-qualoom.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Politica de Privacidad | Qualoom</title>
        <meta name="title" content="Politica de Privacidad | Qualoom"/>
        <meta name="description" content="Politica de Privacidad Qualoom - Por encima de todo, claridad y honestidad - Comprometidos con la protección y la seguridad de los datos de carácter personal."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/politica-de-privacidad/"/>
        <link rel="canonical" href="https://www.qualoom.es/politica-de-privacidad/"/>
        <meta name="robots" content="noindex,nofollow"/>
      </Helmet>
      <BackgroundImage fluid={data.image.childImageSharp.fluid}>
        <div className="background-legal">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Por encima de todo, claridad y honestidad</h1>
              <p>Consulta nuestro Aviso legal, Política de Privacidad y Política de Cookies. Las hemos preparado para que sean sencillas, claras y concisas.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="content">
        <h2>Política de privacidad</h2><hr></hr>
        <p>Qualoom Expertise Technology, S.L. (en adelante, el Titular) con NIF B85437531, y domicilio en Móstoles, Plaza de Valencia, 6 - Bajo Local 28937 Móstoles (Madrid) es titular del Sitio Web accesible a través de la URL: <Link to="/">www.qualoom.es</Link> (en adelante, el Sitio Web). La sociedad se encuentra registrada en el Registro Mercantil de Madrid Tomo: 25605, Folio: 40, Sección: 8, Hoja registral: 461399 e Inscripción: 1.</p>
        <p>Para cualquier consulta, duda o sugerencia respecto al Sitio Web, puedes ponerte en contacto con nosotros a través de la dirección de correo electrónico <a href="mailto:contacto@qualoom.es">contacto@qualoom.es</a>.</p>
        <h3>Responsable del tratamiento</h3>
        <p>La presente Política de Privacidad regula la recogida y tratamiento de los datos personales que sean proporcionados por los Usuarios, cuando accedan, naveguen o hagan uso de las funcionalidades del Sitio Web.</p>
        <h3>Recogida de datos, finalidades, base de legitimación y plazos de conservación</h3>
        <ol>
          <li><p><b>Contacto:</b> El Usuario puede ponerse en contacto, a través de los formularios establecidos a tal efecto en el Sitio Web. Deberá facilitar sus datos identificativos, así como el motivo, asunto o cuestión. Qualoom utilizará esos datos para tramitar la consulta y contactar con el Usuario.</p><p>La base jurídica del tratamiento de tales datos se sustenta en el consentimiento del Usuario.</p><p>El plazo de conservación de los datos para esta finalidad será el un año, salvo que sean aplicables otros plazos.</p></li>
          <li><p><b>Recursos humanos: </b>El Usuario puede ponerse en contacto a través del Sitio Web con Qualoom, para formar parte de los procesos de selección que se estén llevando a cabo o inscribirse en las candidaturas disponibles. Para ello deberá proporcionar sus datos identificativos, así como aquellos referidos a su formación profesional. Tales datos serán utilizados para incluir al Usuario dentro de los procesos de selección de las ofertas de trabajo activas.</p><p>La base jurídica del tratamiento de tales datos se sustenta en el consentimiento del Usuario.</p><p>El plazo de conservación de los datos será de un año.</p></li>
          <li><p><b>Cookies: </b>El Sitio Web dispone de tecnología para la implantación de archivos denominados cookies en el equipo que sea utilizado para el acceso y navegación por el Sitio Web. Las cookies pueden ser bloqueadas o deshabilitadas en cualquier momento a través de las opciones de configuración del navegador. Si desea más información puede consultar nuestra política de Cookies.</p><p>La base jurídica del tratamiento de tales datos se sustenta en el consentimiento del Usuario.</p></li>
          <li><p><b>Publicidad: </b>Si el Usuario lo autoriza expresamente, Qualoom podrá enviarle publicidad de los servicios y/o promociones disponibles en el Sitio Web, como por ejemplo novedades y/o descuentos entre otros. La base jurídica del tratamiento de tales datos se sustenta en el consentimiento del Usuario. Podrá revocar dicho consentimiento en cualquier momento.</p><p>El plazo de conservación de los datos será del tiempo que permanezca dado de alta en nuestros sistemas para el envío de publicidad y no haya revocado su consentimiento.</p></li>
        </ol>
        <h3>Comunicaciones de datos a terceros</h3>
        <p>En principio no se comunicarán los datos a terceros, salvo que medie el consentimiento previo e informado del Usuario o una obligación legal.</p>
        <h3>Ejercicio de derechos</h3>
        <p>Asimismo, el Usuario puede revocar el consentimiento para el tratamiento, haciendo ejercicio de los derechos de acceso, rectificación, supresión, portabilidad, oposición y limitación al tratamiento, poniéndolo en conocimiento de Qualoom a través de un correo electrónico dirigido a contacto@qualoom.es, señalando como asunto “Protección de Datos”. En alguna ocasión especial, podremos solicitar al Usuario que acredite su identidad mediante documento oficial, por ejemplo para evitar el acceso a sus datos por parte de terceros.</p>
        <p>Por último, el Usuario podrá comunicar cualquier modificación o solicitar la baja, indicándolo en la dirección de correo facilitada.</p>
        <p>En cualquier momento, el Usuario podrá presentar una reclamación ante la Agencia Española de Protección de Datos para hacer valer sus derechos.</p>
        <h3>Modificación de la Política de privacidad</h3>
        <p>Qualoom se reserva el derecho de modificar en cualquier momento su política de privacidad, respetando la legislación vigente en materia de protección de datos y previa la debida comunicación a los interesados.</p>
        <h3>Idioma aplicable a la presente política de privacidad</h3>
        <p>El idioma aplicable a esta Política de Privacidad es el español. Cualquier versión de la misma en idioma distinto es ofrecida para comodidad del Usuario y para facilitar su comprensión. No obstante, esta Política de Privacidad se regirá siempre por su versión en español.</p>
        <p>En caso de contradicción entre la Política de Privacidad en cualquier idioma y su versión en español, prevalecerá la versión en español.</p>
      </div>
      <Footer />
    </div>
  )
}

export default Policy
